<template>
    <main id="mainContent" v-if="body">
        <WebsiteHero :body="body" />
        <ContenutoPagina :pagina="body" />
        <DelayHydration>
            <LazyWebsiteFooter />
        </DelayHydration>
    </main>
</template>

<script lang="ts" setup>
const { localeProperties } = useI18n()
const directus = useDirectus()
const directusUrl = useDirectusUrl()

const { data: body, error: bodyError } = await useLazyAsyncData(`homepage-${localeProperties.value.code}`, () => directus('/pages/content/homepage', { query: { locale: localeProperties.value.code } }), { transform: (p: any) => p?.pagine[0] })
if (bodyError.value) throw createError({ statusCode: 404 })

useSeoMeta({
    title: () => body.value && body.value.translations && body.value.translations[0]?.og_title ? body.value.translations[0].og_title : 'Turismo Torino e Provincia',
    ogTitle: () => body.value && body.value.translations && body.value.translations[0]?.og_title ? body.value.translations[0].og_title : 'Turismo Torino e Provincia',
    twitterTitle: () => body.value && body.value.translations && body.value.translations[0]?.og_title ? body.value.translations[0].og_title : 'Turismo Torino e Provincia',

    description: () => body.value && body.value.translations && body.value.translations[0]?.og_description ? body.value.translations[0].og_description : undefined,
    ogDescription: () => body.value && body.value.translations && body.value.translations[0]?.og_description ? body.value.translations[0].og_description : undefined,
    twitterDescription: () => body.value && body.value.translations && body.value.translations[0]?.og_description ? body.value.translations[0].og_description : undefined,

    ogImage: () => body.value && body.value.cover ? `${directusUrl}assets/${body.value.cover.id}?width=1600&format=jpeg` : undefined,
    twitterImage: () => body.value && body.value.cover ? `${directusUrl}assets/${body.value.cover.id}?width=1600&format=jpeg` : undefined,

    ogLocale: () => localeProperties.value.isold,
})
useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'Organization',
            name: 'Turismo Torino e Provincia',
            url: 'https://turismotorino.org',
            logo: {
                '@type': 'ImageObject',
                url: 'https://turismotorino.org/logottp.jpg',
                width: '2000',
                height: '2000'
            },
            contactPoint: {
                '@type': 'ContactPoint',
                email: 'contact@turismotorino.org',
                telephone: '+39 011 535181',
                contactType: 'Torino Contact Centre'
            },
            sameAs: [
                "https://www.facebook.com/turismotorino/",
                "https://instagram.com/turismotorino/",
                "https://www.tiktok.com/@turismotorino",
                "http://twitter.com/turismotorino",
                "http://www.youtube.com/TurismoTorino",
                "https://www.linkedin.com/company/turismo-torino-e-provincia"
            ]
        },
        {
            '@type': 'WebSite',
            '@id': `https://turismotorino.org/${localeProperties.value.code}/#website`,
            url: `https://turismotorino.org/${localeProperties.value.code}`,
            name: 'Turismo Torino e Provincia',
            description: body.value && body.value.translations && body.value.translations[0]?.og_description ? body.value.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: {
                '@type': 'SearchAction',
                target: `https://turismotorino.org/${localeProperties.value.code}/cerca?text={search_term}`,
                'query-input': 'required name=search_term'
            }
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org/${localeProperties.value.code}/#webpage`,
            url: `https://turismotorino.org/${localeProperties.value.code}`,
            name: body.value && body.value.translations && body.value.translations[0]?.og_title ? body.value.translations[0].og_title : 'Turismo Torino e Provincia',
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: body.value && body.value.cover && body.value.cover.height && body.value.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org/${localeProperties.value.code}/#primaryimage`,
                url: `${directusUrl}assets/${body.value.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (body.value.cover.height * 1600 / body.value.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: body.value.cover.title
            } : undefined,
            datePublished: body.value && body.value.date_created ? new Date(body.value.date_created).toJSON() : undefined,
            dateModified: body.value && body.value.date_updated ? new Date(body.value.date_updated).toJSON() : undefined,
            description: body.value && body.value.translations && body.value.translations[0]?.og_description ? body.value.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org/${localeProperties.value.code}`
                    ]
                }
            ]
        }
    ]
}))
</script>